<li class="flex align-items-center py-1">
    <div class=" w-2rem h-2rem flex align-items-center justify-content-center  border-circle mr-3 flex-shrink-0">
        <img [src]="defaultItemImage() || item()[imageProperty()]" class="w-2rem h-2rem" alt="Avatar" />
    </div>
    <p-button [label]="prepareNameProperty()" labelStyleClass="text-2xl" (click)="clicked()" 
    size="small" class="use-button" styleClass="capitalize" (click)="onUseButtonSelected()">
    </p-button>

    @if(showEditButton()) {
    <button (click)="editButtonSelected()" pButton icon="pi pi-pencil" class="edit-button" [text]="true" [size]="'small'"></button>
    }
</li>

@if (isGroup()) {
    <app-student-group-row-display [studentGroupItem]="item()"></app-student-group-row-display>
}
